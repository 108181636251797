import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./routers/AppRouter";
import * as serviceWorker from "./serviceWorker";

import "./styles/styles.scss";
import "normalize.css";

ReactDOM.render(<AppRouter />, document.getElementById("root"));

serviceWorker.unregister();
