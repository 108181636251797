import React from "react";
import GoogleMapReact from "google-map-react";
import Box from "@material-ui/core/Box";
import LocationIcon from "@material-ui/icons/LocationOn";

const MapMarker = () => {
  return (
    <div>
      <Box textAlign="center">
        <LocationIcon />
      </Box>
    </div>
  );
};

const LocationMap = props => {
  const center = {
    lat: props.lat,
    lng: props.lng
  };
  const zoom = 16;
  return (
    <Box width="100%" height="500px" position="relative" zIndex={4}>
      <GoogleMapReact
        options={{ mapTypeControl: false, scrollwheel: false }}
        bootstrapURLKeys={{
          key: "AIzaSyDWmWVzUWsvpIjL0lpCYF0iBi83RkpuhUI",
          language: "tr"
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        scrollwheel={false}
      >
        <MapMarker lat={props.lat} lng={props.lng} />
      </GoogleMapReact>
    </Box>
  );
};

export default LocationMap;
