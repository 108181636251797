import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import ReactGA from "react-ga";
import database from "./../firebase/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";

const Faq = () => {
  ReactGA.initialize("UA-155019820-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [a, setA] = useState([]);
  useEffect(() => {
    database
      .ref("faq")
      .once("value")
      .then(snapshot => {
        const result = snapshot.val();
        setA(result);
      });
  }, []);

  const renderFaq = () => {
    if (a.length === 0) {
      return (
        <Box>
          <CircularProgress />
        </Box>
      );
    }

    return a.map((d, z) => {
      console.log(d);
      return (
        <Box key={z} mb={5}>
          <Box mb={1} fontSize="16px" fontWeight={600}>
            {d.title}
          </Box>
          <Box textAlign="justify" fontSize="16px" lineHeight="30px">
            <p>{d.description}</p>
          </Box>
        </Box>
      );
    });
  };
  return (
    <Box py={8} bgcolor="#f9f9f9">
      <Container maxWidth="md">
        <Box className="title">Sıkça Sorulan Sorular</Box>
        {renderFaq()}
      </Container>
    </Box>
  );
};

export default Faq;
