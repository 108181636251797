import React from "react";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import ReactGA from "react-ga";
const ServiceDetail = props => {
  const slug = props.match.params.slug;
  React.useEffect(() => {
    ReactGA.initialize("UA-155019820-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [slug]);
  const Psikoterapi = () => (
    <Box my={6}>
      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title">Bireysel Psikolojik Danışmanlık</Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Hidden only={["sm", "md", "lg", "xl"]}>
          <Box mb={2}>
            <Image
              alt="Psikoterapi"
              src={require("./../assets/images/psikoterapi.jpg")}
              className="img-fluid "
              aspectRatio={480 / 621}
            />
          </Box>

          <Box fontSize="14px" color="#7e7d7d" mb={3} lineHeight="25px">
            <h2>Psikoterapi</h2>
            <p>
              Bireysel psikoterapi iyilik halinizi ve yaşam doyumunuzu
              arttırmanızda, güçlüklerinizin üstesinden gelmenizde ve ileride
              karşılaşabileceğiniz olası güçlükler karşısında daha donanımlı
              olmanızda size fayda sağlayacaktır.
            </p>
            <p>
              Psikoterapi, bilimsel etkinliği kanıtlanmış yöntemler, etik
              kurallar ve ilkeler esas alınarak yapılandırılan bir süreçtir.
            </p>
            <p>
              Her terapi süreci kişiye özgüdür. Birçok etmene göre seans sayısı
              farklılık gösterebilmektedir. Bir terapi seansı ortalama 50 dakika
              sürmektedir.
            </p>
          </Box>
        </Hidden>

        <Hidden only={["xs"]}>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={12} md={12} className="service-container">
              <Box display="flex" alignItems="center" className="rajdhani">
                <Box flexBasis="54.9%">
                  <Image
                    alt="Psikoterapi"
                    src={require("./../assets/images/psikoterapi.jpg")}
                    className="img-fluid "
                    aspectRatio={480 / 621}
                  />
                </Box>
                <Box flexBasis="44%" ml={3}>
                  <Box pl={3} mb={3} fontSize="18px"></Box>
                  <Box fontSize="14px" color="#7e7d7d" mb={3} lineHeight="25px">
                    <h2>Psikoterapi</h2>
                    <p>
                      Bireysel psikoterapi iyilik halinizi ve yaşam doyumunuzu
                      arttırmanızda, güçlüklerinizin üstesinden gelmenizde ve
                      ileride karşılaşabileceğiniz olası güçlükler karşısında
                      daha donanımlı olmanızda size fayda sağlayacaktır.
                    </p>
                    <p>
                      Psikoterapi, bilimsel etkinliği kanıtlanmış yöntemler,
                      etik kurallar ve ilkeler esas alınarak yapılandırılan bir
                      süreçtir.
                    </p>
                    <p>
                      Her terapi süreci kişiye özgüdür. Birçok etmene göre seans
                      sayısı farklılık gösterebilmektedir. Bir terapi seansı
                      ortalama 50 dakika sürmektedir.
                    </p>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Box>
  );

  const OnlineTherapy = () => (
    <Box my={6}>
      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title">Online Terapi</Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Hidden only={["sm", "md", "lg", "xl"]}>
          <Box mb={2}>
            <Image
              alt="Online Terapi"
              src={require("./../assets/images/online.jpg")}
              className="img-fluid "
              aspectRatio={700 / 500}
            />
          </Box>

          <Box fontSize="14px" color="#7e7d7d" mb={3} lineHeight="25px">
            <h2>Online Terapi</h2>
            <p>
              Bireysel psikoterapi iyilik halinizi ve yaşam doyumunuzu
              arttırmanızda, güçlüklerinizin üstesinden gelmenizde ve ileride
              karşılaşabileceğiniz olası güçlükler karşısında daha donanımlı
              olmanızda size fayda sağlayacaktır.
            </p>
            <p>
              Psikoterapi, bilimsel etkinliği kanıtlanmış yöntemler, etik
              kurallar ve ilkeler esas alınarak yapılandırılan bir süreçtir.
            </p>
            <p>
              Özellikle, dünya çapında mücadele ettiğimiz Covid-19 salgını
              nedeni ile terapi sürecinin başlaması ve sürdürülebilmesi yolunda
              bizlere olanak tanımaktadır.
            </p>
          </Box>

          <Box
            fontSize="14px"
            color="#7e7d7d"
            mb={3}
            lineHeight="25px"
            className="rajdhani"
          >
            <h2>Online Terapi süreci</h2>
            <p>
              Terapi odası dışında teknolojiden faydalanılarak danışan ve
              terapisti bir araya getirmeye olanak sağlar.Terapi süreci yüzyüze
              bireysel terapi sürecinden farklı değildir. Terapi süreci
              yapılandırılır. Görüşme süresi yüzyüze görüşme de olduğu gibi
              ortalama 50 dakikadır.
            </p>
            <h2>Online Terapi ne zaman tercih edilir?</h2>
            <ul>
              <li> Covid 19 salgınında korunma nedeni ile</li>
              <li>Yüz yüze konuşmaktan çekinme durumunda</li>
              <li>Yalnız bırakamayacağınız kişiler olduğunda</li>
              <li>
                Yurtdışında yaşıyor ve Anadili Türkçe olan bir terapist’e
                ihtiyaç duyduğunuzda
              </li>
              <li>
                Görüşmeye başladığınız veya görüşmek isteğiniz terapistinizle
                aynı şehirde olmadığınız durumlarda
              </li>
              <li>
                Psikoterapi hizmetine ulaşamadığınız bir bölgede oldunuğunuzda
              </li>
              <li>
                Veya kimi farklı sebeplerden ötürü terapi merkezine
                gidemediğiniz durumlarda online terapiyi tercih edebilirsiniz
              </li>
            </ul>

            <h2>Online terapi sürecinde dikkat edilmesi gerekenler</h2>
            <p>
              Online terapi süreci her birey için uygun değildir. İntihar
              eğilimi olan, kendine veya başkalarına zarar verme olasılığı olan
              bireyler öncelikli olarak yüz yüze görüşme yöntemini tercih
              etmelidir.
            </p>

            <h2>Nasıl Online Terapi randevusu alınır?</h2>
            <p>
              Online terapinin size daha uygun olacağını düşünüyorsanız.
              Mümkünse terapistinizle kısa bir ön görüşme yapmanızda fayda
              olacaktır. Böylelikle online terapinin sizin için uygun olup
              olmadığını netleştirebilir ve sürece dair bilgi alabilirsiniz.
            </p>
          </Box>
        </Hidden>

        <Hidden only={["xs"]}>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={12} md={12} className="service-container">
              <Box display="flex" alignItems="center" className="rajdhani">
                <Box flexBasis="54.9%">
                  <Image
                    alt="Online Terapi"
                    src={require("./../assets/images/online.jpg")}
                    className="img-fluid "
                    aspectRatio={700 / 500}
                  />
                </Box>
                <Box flexBasis="44%" ml={3}>
                  <Box pl={3} mb={3} fontSize="18px"></Box>
                  <Box fontSize="14px" color="#7e7d7d" mb={3} lineHeight="25px">
                    <h2>Online Terapi</h2>
                    <p>
                      Bireysel psikoterapi iyilik halinizi ve yaşam doyumunuzu
                      arttırmanızda, güçlüklerinizin üstesinden gelmenizde ve
                      ileride karşılaşabileceğiniz olası güçlükler karşısında
                      daha donanımlı olmanızda size fayda sağlayacaktır.
                    </p>
                    <p>
                      Psikoterapi, bilimsel etkinliği kanıtlanmış yöntemler,
                      etik kurallar ve ilkeler esas alınarak yapılandırılan bir
                      süreçtir.
                    </p>
                    <p>
                      Özellikle, dünya çapında mücadele ettiğimiz Covid-19
                      salgını nedeni ile terapi sürecinin başlaması ve
                      sürdürülebilmesi yolunda bizlere olanak tanımaktadır.
                    </p>
                  </Box>
                </Box>
              </Box>

              <Box
                fontSize="14px"
                color="#7e7d7d"
                mb={3}
                lineHeight="25px"
                className="rajdhani"
              >
                <h2>Online Terapi süreci</h2>
                <p>
                  Terapi odası dışında teknolojiden faydalanılarak danışan ve
                  terapisti bir araya getirmeye olanak sağlar.Terapi süreci
                  yüzyüze bireysel terapi sürecinden farklı değildir. Terapi
                  süreci yapılandırılır. Görüşme süresi yüzyüze görüşme de
                  olduğu gibi ortalama 50 dakikadır.
                </p>
                <h2>Online Terapi ne zaman tercih edilir?</h2>
                <ul>
                  <li> Covid 19 salgınında korunma nedeni ile</li>
                  <li>Yüz yüze konuşmaktan çekinme durumunda</li>
                  <li>Yalnız bırakamayacağınız kişiler olduğunda</li>
                  <li>
                    Yurtdışında yaşıyor ve Anadili Türkçe olan bir terapist’e
                    ihtiyaç duyduğunuzda
                  </li>
                  <li>
                    Görüşmeye başladığınız veya görüşmek isteğiniz
                    terapistinizle aynı şehirde olmadığınız durumlarda
                  </li>
                  <li>
                    Psikoterapi hizmetine ulaşamadığınız bir bölgede
                    oldunuğunuzda
                  </li>
                  <li>
                    Veya kimi farklı sebeplerden ötürü terapi merkezine
                    gidemediğiniz durumlarda online terapiyi tercih
                    edebilirsiniz
                  </li>
                </ul>

                <h2>Online terapi sürecinde dikkat edilmesi gerekenler</h2>
                <p>
                  Online terapi süreci her birey için uygun değildir. İntihar
                  eğilimi olan, kendine veya başkalarına zarar verme olasılığı
                  olan bireyler öncelikli olarak yüz yüze görüşme yöntemini
                  tercih etmelidir.
                </p>

                <h2>Nasıl Online Terapi randevusu alınır?</h2>
                <p>
                  Online terapinin size daha uygun olacağını düşünüyorsanız.
                  Mümkünse terapistinizle kısa bir ön görüşme yapmanızda fayda
                  olacaktır. Böylelikle online terapinin sizin için uygun olup
                  olmadığını netleştirebilir ve sürece dair bilgi alabilirsiniz.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Box>
  );

  const Testler = () => {
    return (
      <Box my={6}>
        <Container>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Box className="title">Testler</Box>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="md">
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <Box mb={2}>
              <Image
                alt="Psikoterapi"
                src={require("./../assets/images/test1.jpg")}
                className="img-fluid "
                aspectRatio={490 / 373}
              />
            </Box>
            <Box fontSize="14px" color="#7e7d7d" mb={3} lineHeight="25px">
              <h2>Objektif Testler</h2>
              <ul>
                <li>Wechsler Çocuklar İçin Zeka Ölçeği (WÇZÖ IV) </li>
                <li>
                  Beery Buktenica Gelişimsel Görsel Motor Koordinasyon Testi
                  (VMI)
                </li>
                <li>Brief Visuospatial Memory Test (BVMT-R )</li>
                <li>Bender Gestalt Görsel Motor Algılama Testi (BGT)</li>
                <li>Benton Visual Retention Test (BVRT)</li>
              </ul>
            </Box>

            <Box mb={2}>
              <Image
                alt="Psikoterapi"
                src={require("./../assets/images/test2.jpg")}
                className="img-fluid "
                aspectRatio={490 / 445}
              />
            </Box>
            <Box fontSize="14px" color="#7e7d7d" mb={3} lineHeight="25px">
              <h2>Projektif Testler</h2>
              <ul>
                <li>​​​Tematik Algı Testi (TAT)</li>
                <li>Minnesota Çok Yönlü Kişilik Envanteri (MMPI) </li>
                <li>Güvenli Yer Senaryoları Testi (GYST)</li>
                <li>Bir İnsan Çiz Testi (GOOD ENOUGH – HARRiS)</li>
              </ul>
            </Box>
          </Hidden>

          <Hidden only={["xs"]}>
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={12} md={12} className="service-container">
                <Box display="flex" alignItems="center" className="rajdhani">
                  <Box flexBasis="54.9%">
                    <Image
                      alt="Psikoterapi"
                      src={require("./../assets/images/test1.jpg")}
                      className="img-fluid "
                      aspectRatio={490 / 373}
                    />
                  </Box>
                  <Box flexBasis="44%" ml={3}>
                    <Box pl={3} mb={3} fontSize="18px"></Box>
                    <Box
                      fontSize="14px"
                      color="#7e7d7d"
                      mb={3}
                      lineHeight="25px"
                    >
                      <h2>Objektif Testler</h2>
                      <ul>
                        <li>Wechsler Çocuklar İçin Zeka Ölçeği (WÇZÖ IV) </li>
                        <li>
                          Beery Buktenica Gelişimsel Görsel Motor Koordinasyon
                          Testi (VMI)
                        </li>
                        <li>Brief Visuospatial Memory Test (BVMT-R )</li>
                        <li>
                          Bender Gestalt Görsel Motor Algılama Testi (BGT)
                        </li>
                        <li>Benton Visual Retention Test (BVRT)</li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item container spacing={3}>
              <Grid item xs={12} sm={12} md={12} className="service-container">
                <Box display="flex" alignItems="center" className="rajdhani">
                  <Box flexBasis="44%" ml={3}>
                    <Box pl={3} mb={3} fontSize="18px"></Box>
                    <Box
                      fontSize="14px"
                      color="#7e7d7d"
                      mb={3}
                      lineHeight="25px"
                    >
                      <h2>Projektif Testler</h2>
                      <ul>
                        <li>​​​Tematik Algı Testi (TAT)</li>
                        <li>Minnesota Çok Yönlü Kişilik Envanteri (MMPI) </li>
                        <li>Güvenli Yer Senaryoları Testi (GYST)</li>
                        <li>Bir İnsan Çiz Testi (GOOD ENOUGH – HARRiS)</li>
                      </ul>
                    </Box>
                  </Box>

                  <Box flexBasis="54.9%">
                    <Image
                      alt="Psikoterapi"
                      src={require("./../assets/images/test2.jpg")}
                      className="img-fluid "
                      aspectRatio={490 / 445}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </Container>
      </Box>
    );
  };

  const renderService = () => {
    if (slug === "psikoterapi") {
      return <Psikoterapi />;
    } else if (slug === "testler") {
      return <Testler />;
    } else if (slug === "online-terapi") {
      return <OnlineTherapy />;
    } else {
      return <Box>Sayfa Bulunamadı</Box>;
    }
  };

  return renderService();
};

export default ServiceDetail;
