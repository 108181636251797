import React from "react";
import Box from "@material-ui/core/Box";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";

const Footer = () => {
  return (
    <Box borderTop={1} borderColor="#ededed" py={5} mt={5}>
      <Box
        textAlign="center"
        justifyContent="center"
        display="flex"
        alignContent="center"
        flexDirection="row"
      >
        <Box>
          <Box
            color="#5a5a5a"
            component="a"
            href="https://www.instagram.com/psk.yamackaraboncuk/"
            target="_blank"
          >
            <InstagramIcon />
          </Box>
        </Box>

        <Box>
          <Box
            color="#5a5a5a"
            href="https://www.facebook.com/psk.yamackaraboncuk/"
            target="_blank"
          >
            <FacebookIcon style={{ marginRight: "10px", marginLeft: "10px" }} />
          </Box>
        </Box>

        <Box>
          <Box
            color="#5a5a5a"
            component="a"
            href="https://www.linkedin.com/in/arda-yamac-karaboncuk-b6a29985/"
            target="_blank"
          >
            <LinkedinIcon />
          </Box>
        </Box>
      </Box>
      <Box textAlign="center" fontSize="13px" color="#bbbbbb" mt={2}>
        <p>
          <a
            style={{ color: "#bbb", textDecoration: "none" }}
            href="mailto:info@yamackaraboncuk.com"
          >
            info@yamackaraboncuk.com
          </a>
        </p>

        <p>
          <a
            style={{ color: "#bbb", textDecoration: "none" }}
            href="tel:+903129113840"
          >
            +90 312 911 38 40
          </a>
        </p>

        <p> ©2020 Arda Yamaç Karaboncuk.Tüm Hakkı Saklıdır.</p>
      </Box>
    </Box>
  );
};

export default Footer;
