import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAQSezqMXRHvjYk9dzZ_ZzMBq0QDfi9zIU",
  authDomain: "yamac-karaboncuk.firebaseapp.com",
  databaseURL: "https://yamac-karaboncuk.firebaseio.com",
  projectId: "yamac-karaboncuk",
  storageBucket: "yamac-karaboncuk.appspot.com",
  messagingSenderId: "101768155681",
  appId: "1:101768155681:web:083a2f2d957cf603270960",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const database = firebase.database();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { firebase, storage, googleAuthProvider, database as default };
