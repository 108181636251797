import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import ReactGA from "react-ga";

const About = () => {
  ReactGA.initialize("UA-155019820-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Box my={6}>
      <Container maxWidth="xs">
        <Box mb={3} textAlign="center">
          <img
            src={require("./../assets/images/yamac-karaboncuk.jpg")}
            style={{ maxWidth: "80%", margin: "auto" }}
            alt="Yamaç Karaboncuk"
          />
        </Box>
      </Container>

      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center">
              Arda Yamaç Karaboncuk
            </Box>
            <Box className="subTitle" textAlign="center" mt={-2}>
              Klinik Psikolog / Psikoterapist
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md">
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box textAlign="center" lineHeight="30px" mt={5}>
              <p>
                1991 yılında Ankara'da doğdu. Eğitimini tamamladığı kurumlar
                Ankara Özel Tevfik Fikret Lisesi, Bilkent Üniversitesi Psikoloji
                Bölümü ve Başkent Üniversitesi Klinik Psikoloji Tezli Yüksek
                Lisans Programı'dır. Klinik stajını Gülhane Eğitim ve Araştırma
                Hastanesi'nde yapmıştır.
              </p>
              <p>
                Hacettepe Üniversitesi İhsan Doğramacı Çocuk Hastanesi
                Metabolizma, Nöroloji, Çocuk ve Ergen Ruh Sağlığı Bölümlerince
                yürütülen çeşitli klinik araştırmalarda araştırmacı psikolog
                olarak görev almaktadır.
              </p>
              <p>
                Fenilketonüri ve Diğer Metabolik Hastalıklı Çocuklar Vakfı
                (METVAK) da Hiperfenilalinli çocuklarla yürütülen iki yıllık
                çalışmanın tamamlanmasının ardından iki yıl Ferhunde Öktem Ruh
                Sağlığı merkezinde çalışmıştır.
              </p>
              <p>
                Şu anda klinik araştırmaların yanı sıra bireysel (yetişkin)
                psikolojik danışmanlık vermektedir.
              </p>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md">
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center" mt={5}>
              Özgeçmiş (Curriculum Vitae)
            </Box>
            <Box className="subTitle">
              <Box
                component="a"
                href="https://www.yamackaraboncuk.com/cv/yamac-karaboncuk-cv-tr.pdf"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">Türkçe</Box>

                  <Box className="publishment__date">
                    <span>Güncelleme</span> Mart, 2020
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>

              <Box
                component="a"
                href="https://www.yamackaraboncuk.com/cv/yamac-karaboncuk-cv-en.pdf"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">English</Box>

                  <Box className="publishment__date">
                    <span>Last Updated</span> March, 2020
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md">
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center" mt={5}>
              Bilimsel Çalışmalar ve Yayınlar
            </Box>
            <Box className="subTitle">
              <Box
                component="a"
                href="https://www.turkishjournalpediatrics.org/uploads/pdf_TJP_2352.pdf"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">
                    COVID-19-related anxiety in phenylketonuria patients
                  </Box>

                  <Box className="publishment__date">
                    <span>Yazar</span> - Ekim, 2021
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>

              <Box
                component="a"
                href="https://dergipark.org.tr/en/download/article-file/1350918"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">
                    Effects of A Phenylketonuria Camp on Knowledge and Attitudes
                    of Adolescents with Phenylketonuria
                  </Box>

                  <Box className="publishment__date">
                    <span>Yazar</span> - Şubat, 2021
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>

              <Box
                component="a"
                href="https://www.sciencedirect.com/science/article/pii/S0929693X20302499?dgcid=coauthor"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">
                    Comparative analysis of the WISC-IV in a clinical setting:
                    ADHD vs. non-ADHD
                  </Box>

                  <Box className="publishment__date">
                    <span>Yazar</span> - Aralık, 2020
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>

              <Box
                component="a"
                href="http://dspace.baskent.edu.tr:8080/bitstream/handle/11727/4168/10284724.pdf"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">Yüksek Lisans Tezi</Box>

                  <Box className="publishment__date">
                    <span>Yazar</span> - Ağustos, 2019
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>

              <Box
                component="a"
                href="https://dx.doi.org/10.1007/s00431-019-03387-8"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">
                    Maternal phenylketonuria in Turkey: outcomes of 71
                    pregnancies and issues in management.
                  </Box>

                  <Box className="publishment__date">
                    <span>Araştırmacı</span> - Şubat, 2019
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>

              <Box
                component="a"
                href="https://doi.org/10.24953/turkjped.2018.06.001"
                target="_blank"
                className="publishment__container"
                display="flex"
              >
                <Box flexGrow="1">
                  <Box className="publishment__title">
                    Cognitive and behavioral impairment in mild
                    hyperphenylalaninemia
                  </Box>

                  <Box className="publishment__date">
                    <span>Yazar</span> - Haziran, 2018
                  </Box>
                </Box>
                <Box className="publishment__icon">
                  <PdfIcon fontSize="inherit" />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
