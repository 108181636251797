import React from "react";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import ReactGA from "react-ga";
const Services = () => {
  ReactGA.initialize("UA-155019820-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Box my={6}>
      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center">
              Hizmetler
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Hidden only={["sm", "md", "lg", "xl"]}>
        <Container maxWidth="md">
          <Box mb={3} style={{ borderBottom: "1px solid #ccc" }} pb={4}>
            <Link to={`/hizmetler/psikoterapi`}>
              <Image
                alt="Psikoterapi"
                src={require("./../assets/images/terapi.jpg")}
                className="img-fluid "
                aspectRatio={700 / 500}
              />
            </Link>
            <Box mb={2} mt={2} fontSize="18px" textAlign="center">
              Bireysel Psikolojik Danışmanlık
            </Box>
            <Box fontSize="16px" color="#7e7d7d" mb={3} textAlign="center">
              Bireysel psikoterapi iyilik halinizi ve yaşam doyumunu
              arttırmanızda, güçlüklerin üstesinden gelmenizde ve ileride
              karşılaşabileceğiniz olası güçlükler karşısında daha donanımlı
              olmanızda size fayda sağlayacaktır.
            </Box>
            <Box textAlign="center">
              <Link
                to={`/hizmetler/psikoterapi`}
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  marginTop: "20px"
                }}
              >
                Detaylar
              </Link>
            </Box>
          </Box>

          <Box mb={3} style={{ borderBottom: "1px solid #ccc" }} pb={4}>
            <Link to={`/hizmetler/online-terapi`}>
              <Image
                alt="Testler"
                src={require("./../assets/images/online.jpg")}
                className="img-fluid "
                aspectRatio={700 / 500}
              />
            </Link>
            <Box mb={2} mt={2} fontSize="18px" textAlign="center">
              Online Terapi
            </Box>
            <Box fontSize="16px" color="#7e7d7d" mb={3} textAlign="center">
              Online terapi, bireyden bireye farklılık gösteren kimi sebeplerle
              günümüzde oldukça tercih edilen bir görüşme yöntemi olmuştur.
              Özellikle, dünya çapında mücadele ettiğimiz Covid-19 salgını
              nedeni ile terapi sürecinin başlaması ve sürdürülebilmesi yolunda
              bizlere olanak tanımaktadır.
            </Box>
            <Box textAlign="center">
              <Link
                to={`/hizmetler/online-terapi`}
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  marginTop: "20px"
                }}
              >
                Detaylar
              </Link>
            </Box>
          </Box>

          <Box mb={3} style={{ borderBottom: "1px solid #ccc" }} pb={4}>
            <Link to={`/hizmetler/testler`}>
              <Image
                alt="Testler"
                src={require("./../assets/images/test.jpg")}
                className="img-fluid "
                aspectRatio={700 / 500}
              />
            </Link>
            <Box mb={2} mt={2} fontSize="18px" textAlign="center">
              Objectif ve Projektif Testler
            </Box>
            <Box fontSize="16px" color="#7e7d7d" mb={3} textAlign="center">
              Test hizmetleri öncelikli olarak alanda çalışan uzmanların
              bilgisine sunulmuştur. Çeşitli nedenlerle bu testlere ihtiyaç
              duyan bireyler ilgili uzman aracılığıyla iletişime geçebilir veya
              görüşme talep edebilir.
            </Box>
            <Box textAlign="center">
              <Link
                to={`/hizmetler/testler`}
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  marginTop: "20px"
                }}
              >
                Detaylar
              </Link>
            </Box>
          </Box>
        </Container>
      </Hidden>

      <Hidden only={["xs"]}>
        <Container maxWidth="md">
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={12} md={12} className="category-container">
              <Box display="flex" alignItems="center" className="rajdhani">
                <Box flexBasis="54.9%">
                  <Link to={`/hizmetler/psikoterapi`}>
                    <Image
                      alt="Psikoterapi"
                      src={require("./../assets/images/terapi.jpg")}
                      className="img-fluid "
                      aspectRatio={700 / 500}
                    />
                  </Link>
                </Box>
                <Box flexBasis="44%">
                  <Box mb={3} fontSize="18px">
                    Bireysel Psikolojik Danışmanlık
                  </Box>
                  <Box fontSize="16px" color="#7e7d7d" mb={3}>
                    Bireysel psikoterapi iyilik halinizi ve yaşam doyumunu
                    arttırmanızda, güçlüklerin üstesinden gelmenizde ve ileride
                    karşılaşabileceğiniz olası güçlükler karşısında daha
                    donanımlı olmanızda size fayda sağlayacaktır.
                  </Box>
                  <Box>
                    <Link
                      to={`/hizmetler/psikoterapi`}
                      className="a-btn-arrow-2 coda"
                    >
                      <span className="arrow-right"></span>
                      DETAYLAR
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="category-container">
              <Box display="flex" alignItems="center" className="rajdhani">
                <Box flexBasis="54.9%">
                  <Link to={`/hizmetler/online-terapi`}>
                    <Image
                      alt="Testler"
                      src={require("./../assets/images/online.jpg")}
                      className="img-fluid "
                      aspectRatio={700 / 500}
                    />
                  </Link>
                </Box>
                <Box flexBasis="44%">
                  <Box mb={3} fontSize="18px">
                    Online Terapi
                  </Box>
                  <Box fontSize="16px" color="#7e7d7d" mb={3}>
                    Online terapi, bireyden bireye farklılık gösteren kimi
                    sebeplerle günümüzde oldukça tercih edilen bir görüşme
                    yöntemi olmuştur. Özellikle, dünya çapında mücadele
                    ettiğimiz Covid-19 salgını nedeni ile terapi sürecinin
                    başlaması ve sürdürülebilmesi yolunda bizlere olanak
                    tanımaktadır.
                  </Box>
                  <Box>
                    <Link
                      to={`/hizmetler/online-terapi`}
                      className="a-btn-arrow-2 coda"
                    >
                      <span className="arrow-right"></span>
                      DETAYLAR
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="category-container">
              <Box display="flex" alignItems="center" className="rajdhani">
                <Box flexBasis="54.9%">
                  <Link to={`/hizmetler/testler`}>
                    <Image
                      alt="Testler"
                      src={require("./../assets/images/test.jpg")}
                      className="img-fluid "
                      aspectRatio={700 / 500}
                    />
                  </Link>
                </Box>
                <Box flexBasis="44%">
                  <Box mb={3} fontSize="18px">
                    Objectif ve Projektif Testler
                  </Box>
                  <Box fontSize="16px" color="#7e7d7d" mb={3}>
                    Test hizmetleri öncelikli olarak alanda çalışan uzmanların
                    bilgisine sunulmuştur. Çeşitli nedenlerle bu testlere
                    ihtiyaç duyan bireyler ilgili uzman aracılığıyla iletişime
                    geçebilir veya görüşme talep edebilir.
                  </Box>
                  <Box>
                    <Link
                      to={`/hizmetler/testler`}
                      className="a-btn-arrow-2 coda"
                    >
                      <span className="arrow-right"></span>
                      DETAYLAR
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
    </Box>
  );
};

export default Services;
