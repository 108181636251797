import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import parse from "html-react-parser";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ReactGA from "react-ga";

const Blog = () => {
  ReactGA.initialize("UA-155019820-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const getBlogs = async () => {
      const url =
        "https://www.googleapis.com/blogger/v3/blogs/8169976639951959482/posts?key=AIzaSyBWBqNidu0Ux11fgtxq9_rgwd88f-QEJ3o";
      const result = await axios.get(url);
      if (result.data.items) {
        setBlog(result.data.items);
      }
    };

    getBlogs();
  }, []);

  const renderBlog = () => {
    if (blog.length === 0) {
      return <CircularProgress />;
    }

    return blog.map((b, i) => {
      const img = b.content
        .match(/<img [^>]*src="[^"]*"[^>]*>/gm)[0]
        .replace(/.*src="([^"]*)".*/, "$1");
      return (
        <Grid item xs={12} sm={6} key={i}>
          <Link
            to={`/blog/${b.id}/${b.url.replace(
              "http://psikologadresi.blogspot.com/",
              ""
            )}`}
            style={{ color: "#333333", textDecoration: "none" }}
          >
            <Box
              width="100%"
              height="300px"
              style={{
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            ></Box>
            <Box
              fontSize="18px"
              fontWeight="600"
              p={2}
              bgcolor="#ededed"
              textAlign="center"
              borderRadius="0px 0px 7px 7px"
              mb={3}
            >
              {parse(b.title)}
            </Box>
          </Link>
        </Grid>
      );
    });
  };

  return (
    <Box my={6}>
      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center">
              Blog
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid item container spacing={3}>
          {renderBlog()}
        </Grid>
      </Container>
    </Box>
  );
};

export default Blog;
