import React from "react";
import { Parallax } from "react-parallax";
import Plx from "react-plx";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ServiceAll from "./Services";

import ReactGA from "react-ga";
ReactGA.initialize("UA-155019820-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Slide = () => {
  const parallaxData2 = [
    {
      start: 0,
      duration: 800,

      properties: [
        {
          startValue: 0,
          endValue: -8250,
          property: "translateX"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxData3 = [
    {
      start: 0,
      duration: 800,

      properties: [
        {
          startValue: 0,
          endValue: 8250,
          property: "translateX"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];

  return (
    <Parallax
      bgImage={require("./../assets/images/bulut.jpg")}
      className="bg"
      strength={-100}
    >
      <div style={{ height: "70vh" }}>
        <Plx className="MyAwesomeParallax" parallaxData={parallaxData2}>
          <Box className="cloud">
            <img alt="Bulut 1" src="/img/bulut1.png" />
          </Box>
        </Plx>

        <Plx className="MyAwesomeParallax" parallaxData={parallaxData3}>
          <Box className="cloud2">
            <img alt="Bulut 2" src="/img/bulut2.png" />
          </Box>
        </Plx>

        <Box className="slider__inner">
          <span>
            <img
              alt="Zihin değişir hayat değişir"
              src={require("./../assets/images/yazi.png")}
              style={{ maxWidth: "100%" }}
            />
          </span>
        </Box>
      </div>
    </Parallax>
  );
};

const Service = () => {
  return (
    <Box py={8} bgcolor="#f9f9f9">
      <Container>
        <Grid item container spacing={3} className="service">
          <Grid item xs={12} sm={6}>
            <Box className="title">Destek Alabileceğiniz Alanlar</Box>
            <Box className="content" mb={8}>
              <p>
                Psikoterapi'den beklenen kazanımlar kişiden kişiye önemli ölçüde
                farklılıklar gösterebilmekle birlikte fayda görebileceğiniz bazı
                alanlar şu şekilde olabilir.
              </p>
            </Box>

            <Box>
              <Box className="title--service">Psikolojik Güçlükler</Box>

              <Box className="service__holder">
                <Box>
                  <img
                    src={require(`./../assets/images/homeIcon/beyin.png`)}
                    alt="Psikolojik Güçlükler"
                  />
                </Box>

                <Box>
                  <ul>
                    <li>Kaygı sorunları (sosyal kaygı, fobiler, takıntılar)</li>
                    <li>Depresif ruh hali, mutsuz veya umutsuz hissetme</li>
                    <li>Yeme davranışı sorunu</li>
                    <li>Psikolojik kökenli fiziksel şikayetler</li>
                  </ul>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box className="title--service">
                İlişki Alanında Yaşanan Güçlükler{" "}
              </Box>

              <Box className="service__holder">
                <Box>
                  <img
                    src={require(`./../assets/images/homeIcon/iliski.png`)}
                    alt="İlişki"
                  />
                </Box>

                <Box>
                  <ul>
                    <li>Duygusal ilişki sorunları</li>
                    <li>İletişim sorunları</li>
                    <li>Güven ve kıskançlık sorunları</li>
                    <li>
                      Aile, arkadaş, iş, okul hayatındaki ilişki sorunları
                    </li>
                    <li>Duyguları ifade etmede güçlük</li>
                    <li>Kayıp yaşamaya ilişkin korkular</li>
                    <li>Kayıp ve yas sürecine ilişkin sorunlar</li>
                  </ul>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box className="title--service">
                Bireysel Alanda Yaşanan Güçlükler
              </Box>

              <Box className="service__holder">
                <Box>
                  <img
                    src={require(`./../assets/images/homeIcon/bireysel.png`)}
                    alt="Bireysel"
                  />
                </Box>

                <Box>
                  <ul>
                    <li>Yaşantısal krizlerle baş etme</li>
                    <li>Yalnız hissetme</li>
                    <li>Kendini ifade etmede yaşanan güçlük</li>
                    <li>Özgüven alanında sorunlar</li>
                    <li>Girişkenlik sorunları</li>
                    <li>Kendinden memnuniyetsizlik yaşama</li>
                    <li>Sınır sorunları</li>
                    <li>Suçluluk ve utanç duyguları</li>
                    <li>Bireyselleşememe veya ayrışama</li>
                    <li>Başkaları tarafından yargılanma kaygıları</li>
                    <li>Öfke yönetimi</li>
                    <li>Karar almada güçlük</li>
                    <li>Uyum sorunları</li>
                    <li>Uyku sorunları</li>
                  </ul>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box className="title--service">
                Performans Alanına İlişkin Güçlükler
              </Box>

              <Box className="service__holder">
                <Box>
                  <img
                    src={require(`./../assets/images/homeIcon/performans.png`)}
                    alt="Performans"
                  />
                </Box>

                <Box>
                  <ul>
                    <li>İş/okul yaşamına ilişkin sorunlar</li>
                    <li>Zaman yönetimi</li>
                    <li>Stres yönetimi</li>
                    <li>Motivasyonel sorunlar</li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              className="parallax"
              style={{
                backgroundImage: `url(${require("./../assets/images/kadin.jpg")})`
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const Homepage = () => {
  return (
    <React.Fragment>
      <Slide />
      <ServiceAll />

      <Service />
    </React.Fragment>
  );
};

export default Homepage;
