import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ReactGA from "react-ga";

const Blog = props => {
  console.log(props);
  ReactGA.initialize("UA-155019820-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const slug = props.match.params.slug;
  const [blog, setBlog] = useState({});

  useEffect(() => {
    const getBlogs = async () => {
      const url = `https://www.googleapis.com/blogger/v3/blogs/8169976639951959482/posts/${slug}?key=AIzaSyBWBqNidu0Ux11fgtxq9_rgwd88f-QEJ3o`;

      const result = await axios.get(url);
      if (result.data) {
        setBlog(result.data);
      }
    };

    getBlogs();
  }, [slug]);
  return (
    <Box my={6}>
      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center">
              {blog.title}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="blog">{blog.content && parse(blog.content)}</Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Blog;
