import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

const Header = () => {
  const [showMobile, setShowMobile] = useState(document.body.offsetWidth >= 800 ? false : true);

  const closeMenu = () => {
    console.log("geldi");
    document.getElementById("drop").checked = false;
  };
  useEffect(() => {
    const setMobile = (e) => {
      const width = e.srcElement.innerWidth;
      if (width >= 800) {
        setShowMobile(false);
      } else {
        setShowMobile(true);
      }
    };

    window.addEventListener("resize", setMobile);
    return () => {
      window.removeEventListener("resize", setMobile);
    };
  }, []);

  const Menu = () => {
    return (
      <Box bgcolor="#000000">
        <Container>
          <Box className="header">
            <Box py={3}>
              <Link to="/">
                <img alt="Psikolog Yamaç Karaboncuk" src={require("./../assets/images/logo-yk.png")} />
              </Link>
            </Box>
            <Box py={3}>
              <ul className="header-menu">
                <li>
                  <Link to="/">Anasayfa</Link>
                </li>
                <li>
                  <Link to="/hakkimda">Hakkımda</Link>
                </li>
                <li>
                  <Link to="/hizmetler">Hizmetler</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/sikca-sorulan-sorular">Sıkça Sorulan Sorular</Link>
                </li>

                <li>
                  <a href="#randevu">
                    <span style={{ color: "#00bef9" }}>Randevu</span>
                  </a>
                </li>

                <li>
                  <Link to="/iletisim">İletişim</Link>
                </li>
              </ul>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  };
  const MobileMenu = () => (
    <Box bgcolor="#000000">
      <Container>
        <Box py={3}>
          <nav>
            <label htmlFor="drop" className="toggle">
              <Box
                component="img"
                src={require("./../assets/images/logo-yk.png")}
                className="img-fluid"
                maxHeight="100%"
                maxWidth="200px"
                height="32px !important"
              />
            </label>
            <input type="checkbox" id="drop" />
            <ul className="menu">
              <li>
                <Link to="/" onClick={closeMenu}>
                  Homepage
                </Link>
              </li>

              <li>
                <Link to="/hakkimda" onClick={closeMenu}>
                  Hakkımda
                </Link>
              </li>
              <li>
                <Link to="/hizmetler" onClick={closeMenu}>
                  Hizmetler
                </Link>
              </li>

              <li>
                <Link to="/hizmetler/psikoterapi" onClick={closeMenu}>
                  <Box pl={3}>Bireysel Psikolojik Danışmanlık</Box>
                </Link>
              </li>
              <li>
                <Link to="/hizmetler/online-terapi" onClick={closeMenu}>
                  <Box pl={3}>Online Terapi</Box>
                </Link>
              </li>
              <li>
                <Link to="/hizmetler/testler" onClick={closeMenu}>
                  <Box pl={3}>Objectif ve Projektif Testler</Box>
                </Link>
              </li>
              <li>
                <Link to="/blog" onClick={closeMenu}>
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/sikca-sorulan-sorular" onClick={closeMenu}>
                  Sıkça Sorulan Sorular
                </Link>
              </li>
              <li>
                <a href="#randevu">Randevu</a>
              </li>
              <li>
                <Link to="/iletisim" onClick={closeMenu}>
                  İletişim
                </Link>
              </li>
            </ul>
          </nav>
        </Box>
      </Container>
    </Box>
  );

  return showMobile ? <MobileMenu /> : <Menu />;
};

export default Header;
