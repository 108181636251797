import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LocationMap from "./LocationMap";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import validator from "validator";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import ReactGA from "react-ga";
const Contact = () => {
  ReactGA.initialize("UA-155019820-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const handleSubmit = async (e) => {
    setButtonStatus(true);
    setSuccess("");
    setError("");
    e.preventDefault();
    if (!name) {
      setButtonStatus(false);
      return setError("İsim girilmesi gereklidir");
    }

    if (!email) {
      setButtonStatus(false);
      return setError("E-posta girilmesi gereklidir");
    }

    if (!validator.isEmail(email)) {
      setButtonStatus(false);
      return setError("Geçerli bir e-posta adresi gereklidir");
    }

    if (!subject) {
      setButtonStatus(false);
      return setError("Başlık girilmesi gereklidir");
    }

    if (!message) {
      setButtonStatus(false);
      return setError("Mesaj girilmesi gereklidir");
    }

    try {
      const result = await axios.post(
        "https://yamacapi.herokuapp.com/api/mail",
        {
          name,
          email,
          phone,
          subject,
          message,
        }
      );
      if (result.data.status === "success") {
        setError("");
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        setButtonStatus(false);
        setSuccess("Mesajınız başarıyla gönderilmiştir");
      } else {
        setButtonStatus(false);
        setError("Bir hata oluştu. Lütfen tekrar deneyin");
      }
    } catch (e) {
      setButtonStatus(false);
      setError("Bir hata oluştu. Lütfen tekrar deneyin");
    }
  };

  return (
    <Box my={6}>
      <Container>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Box className="title" textAlign="center">
              İletişim
            </Box>
            <Box>
              <LocationMap lat={39.90646071993465} lng={32.860246283114535} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box my={3}>
              <Box className="title" textAlign="center">
                İletişim Bilgileri
              </Box>
              <Box textAlign="center" lineHeight="30px">
                <p>
                  Remzi Oğuz Arık Mah. Bestekar Sk. 61C/7
                  <br /> Çankaya Ankara
                </p>

                <p>
                  <Box
                    component="a"
                    color="#000000"
                    href="mailto:info@yamackaraboncuk.com"
                  >
                    info@yamackaraboncuk.com
                  </Box>
                </p>

                <p>
                  <Box component="a" color="#000000" href="tel: +905468910141">
                    +90 546 891 01 41
                  </Box>
                </p>

                <p>
                  <Box component="a" color="#000000" href="tel: +903129113840">
                    +90 312 911 38 40
                  </Box>
                </p>
              </Box>
              <Box className="title" textAlign="center" mt={3}>
                Sosyal Medya
              </Box>
              <Box
                textAlign="center"
                justifyContent="center"
                display="flex"
                alignContent="center"
                flexDirection="row"
              >
                <Box>
                  <Box
                    color="#3878c7"
                    component="a"
                    href="https://www.instagram.com/psk.yamackaraboncuk/"
                    target="_blank"
                  >
                    <InstagramIcon fontSize="large" />
                  </Box>
                </Box>

                <Box>
                  <Box
                    component="a"
                    color="#3878c7"
                    href="https://www.facebook.com/psk.yamackaraboncuk/"
                    target="_blank"
                  >
                    <FacebookIcon
                      fontSize="large"
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box
                    color="#3878c7"
                    component="a"
                    href="https://www.linkedin.com/in/arda-yamac-karaboncuk-b6a29985/"
                    target="_blank"
                  >
                    <LinkedinIcon fontSize="large" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box my={3}>
              <Box className="title" textAlign="center">
                İletişim Formu
              </Box>
              {error && (
                <Box
                  border={1}
                  color="#D8000C"
                  bgcolor="#FFD2D2"
                  borderColor="#D8000C"
                  borderRadius="5px"
                  px={4}
                  py={2}
                  textAlign="center"
                  mb={2}
                >
                  {error}
                </Box>
              )}
              {success && (
                <Box
                  border={1}
                  color="#4F8A10"
                  bgcolor="#DFF2BF"
                  borderColor="#4F8A10"
                  borderRadius="5px"
                  px={4}
                  py={2}
                  textAlign="center"
                  mb={2}
                >
                  {success}
                </Box>
              )}
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="İsim Soyisim"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>
                <Grid item container spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        type="email"
                        label="E-Posta"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Telefon"
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Başlık"
                    variant="outlined"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    multiline
                    label="Mesajınız"
                    fullWidth
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Box>
                <Box mb={2} textAlign="center">
                  <Button
                    type="submit"
                    style={{ borderColor: "#3878c7", color: "#3878c7" }}
                    variant="outlined"
                    color="primary"
                    disabled={buttonStatus}
                  >
                    Gönder
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
