import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import ScrollToTop from "./ScrollToTop";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import Homepage from "./../components/Homepage";
import Contact from "../components/Contact";
import About from "../components/About";
import Services from "../components/Services";
import ServiceDetail from "../components/ServiceDetail";
import Blog from "../components/Blog";
import BlogDetail from "../components/BlogDetail";
import Faq from "../components/Faq";
export const history = createBrowserHistory();

const AppRouter = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Header />
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/iletisim" exact component={Contact} />
          <Route path="/hakkimda" exact component={About} />
          <Route path="/hizmetler" exact component={Services} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/sikca-sorulan-sorular" exact component={Faq} />
          <Route path="/blog/:slug/*" exact component={BlogDetail} />
          <Route path="/hizmetler/:slug" exact component={ServiceDetail} />
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;
